import React from 'react';
import styled from 'styled-components';


const Cottation = () => {
  return (
      <>
          <svg xmlns="http://www.w3.org/2000/svg" width="132.914" height="60" viewBox="0 0 132.914 60">
              <g id="Quote" transform="translate(-334.124 -2092.65)">
                  <path id="Path_35" data-name="Path 35" d="M10146.8,175.082l61.1-35.282V115.082h-61.1Z" transform="translate(-9812.676 1977.568)" fill="#fff"/>
                  <path id="Path_5761" data-name="Path 5761" d="M10146.8,175.082l61.1-35.282V115.082h-61.1Z" transform="translate(-9740.866 1977.568)" fill="#fff"/>
              </g>
          </svg>
      </>
  );
};


export default React.memo(Cottation);
