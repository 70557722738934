'use client'
import React, {useState} from 'react';
import styled from "styled-components";
import {text, Transition, white, hover} from "@/styles/globalStyleVars";
import Link from "next/link";
import {Img} from "@/components/common/ui/Img";
import reactHtmlParser from "react-html-parser";


const DestinationSingle = ({title, description, link, logo, offset, background}) => {


    return (
        <StyledDestinationSingle offset={offset} className='destinationSingle'  >
            <Img  alt={title} src={background ? background : '/images/dynamic/common/autumn-view-vancouver-british-columbi.jpg'}/>
            <Link prefetch={true}  href={link ? link: '#'}></Link>
            <div className="top_content" data-speed={'0.95'}>
                <div className="icon-flag">
                    <img src={logo ? logo : "/images/dynamic/common/canada.svg"} alt={title}/>
                </div>
                <h6>{title ? reactHtmlParser(title) : 'Canada'}</h6>
            </div>
            <div className="icon" data-speed={'0.95'}>
                <div className="content">
                <p>{description ? reactHtmlParser(description) : 'The country\'s diverse cultural landscape and strong emphasis on inclusivity provide a supportive setting for international students.'}</p>
                </div>
                <div className="icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                        <g id="Button" transform="translate(11553 16554)">
                            <g id="Ellipse_449" data-name="Ellipse 449" transform="translate(-11553 -16554)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="20" cy="20" r="20" stroke="none"/>
                                <circle cx="20" cy="20" r="19.5" fill="none"/>
                            </g>
                            <circle id="Ellipse_450" data-name="Ellipse 450" cx="20" cy="20" r="0" transform="translate(-11553 -16554)" fill="#fb5400"/>
                            <g id="Arrow" transform="translate(-12448.703 -16285.806) rotate(-45)">
                                <g id="Group_4823" data-name="Group 4823" transform="translate(823 467)">
                                    <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                    <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                </g>
                                <line id="Line_11" data-name="Line 11" x2="10" transform="translate(818 472)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>

        </StyledDestinationSingle>
    );
};

const StyledDestinationSingle = styled.div`
    background: ${text};
    padding: 40px;
    border-radius: 8px;
    position: relative;
    min-height: 460px;
    overflow: hidden;

    @media(min-width: 1800px){
        min-height: 590px;
    }
    .global-image {
        &:after {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            content: '';
        }
        
        img{
            transition: 0.7s all ${Transition};
            transform: scale(1.01);
        }
    }

    a {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 3;
    }

    .top_content {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        .icon-flag{
            position: relative;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 12px;
            img{
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
     

        h6 {
            color: ${white};
        }
    }


    p {
        color: ${white};
    }

    .icon {
        position: absolute;
        right: 0;
        bottom: 40px;
        display: flex;
        align-items: flex-end;
        gap: 30px;
        justify-content: space-between;
        left: 0;
        width: calc(100% - 80px);
        margin: 0 auto;

        p{
            margin-bottom: 0 !important;
        }
        span {
            letter-spacing: -0.12px;
            font-size: 12px;
            line-height: 16px;
            color: ${white};
            font-weight: 400;
            margin-right: 20px;
        }

        svg {
            transition: 0.7s all ${Transition};
            transform: rotate(45deg);

            #Ellipse_450 {
                transition: 0.7s all ${Transition};

            }
        }
    }


    &:hover {
        .icon {
            svg {
                transform: rotate(0);

                #Ellipse_450 {
                    r: 20px;

                }
            }
        }

        .global-image {
            img{
                transition: 0.7s all ${Transition};
                transform: scale(1.03);
            }
        }
    }

    @media (min-width: 1536px) {

    }
    @media (max-width: 767px) {
        padding: 25px !important;
        
        .icon{
            width: calc(100% - 50px);
            bottom: 25px;
        }
    }
`;

export default React.memo(DestinationSingle);
