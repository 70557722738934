"use client";
import React, {useRef} from "react";
import styled from "styled-components";
import {Col} from "react-bootstrap";
import {hover, text, Transition, white} from "@/styles/globalStyleVars";
import Slider, {Slide} from "@/components/common/ui/swiper";
import {Img} from "@/components/common/ui/Img";
import reactHtmlParser from "react-html-parser";
import ThemeRow from "@/components/common/theme/row";
import Cottation from "@/components/common/ui/icons/Cottation";
import { EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";

const Testimonial = ({data}) => {
    const SwiperRef = useRef();


    // Function to initialize swiper
    const handleSwiperInit = (swiper) => {
        return swiper;
    };


    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '</span>';
        },
    };


    return (
        <StyledComponent>
            <p className={'first_word parallax-xr'}>{data?.section_data?.subtitle}</p>
            <p className={'second_word parallax-x'}>{data?.section_data?.short_desc}</p>
         <ThemeRow>
             <Col md={3} className={'left'} data-speed={'clamp(1.1)'}>
                 <p>Testimonials</p>
                 <Cottation/>

             </Col>
             <Col md={9} data-speed={'clamp(1.13)'}>

                 {
                     data?.posts?.list && data?.posts?.list?.length > 0 &&

                     <Slider
                         ref={SwiperRef}
                         modules={[EffectFade]}
                         navigationLeft={"#testimonial_next"}
                         navigationRight={"#testimonial_prev"}
                         slidesPerView={1}
                         slidesPerGroup={1}
                         effect={"fade"}
                         fadeEffect={{
                             crossFade: true,
                         }}
                         loop={true}
                         autoplay={false}
                         spaceBetween={15}
                         grabCursor={false}
                         watchSlidesProgress={false}
                         mousewheelControl={false}
                         keyboardControl={false}
                         onSwiperInit={handleSwiperInit}
                         pagination={pagination}
                     >


                         {
                             data?.posts?.list && data?.posts?.list?.length > 0 &&
                             data?.posts?.list?.map((e,index) => {

                                 return(
                                     <Slide >
                                         <div  className="single-testimonial">
                                             <div className="header">
                                                 <div className="avatar">
                                                     <Img alt={e?.data?.title} src={e?.images?.[0]?.full_path}/>
                                                 </div>
                                                 <div className="name">
                                                     <p>{e?.data?.title}</p>
                                                     <p>{e?.data?.subtitle}</p>
                                                 </div>
                                             </div>
                                             <div className="content">
                                                 {
                                                     e?.data?.description &&
                                                     reactHtmlParser(e?.data?.description)
                                                 }
                                             </div>
                                         </div>
                                     </Slide>
                                 )
                             })
                         }

                     </Slider>
                 }


                 <ul className={'slider-nav'}>
                     <li id={'testimonial_prev'}></li>
                     <li id={'testimonial_next'}>


                     </li>
                 </ul>
             </Col>
         </ThemeRow>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: ${hover};
    padding: 200px 0;
    position: relative;
    overflow: hidden;

    .col-md-9 {
        position: relative;
    }

    .slider-nav {
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        z-index: 9;
        width: 100%;
        height: 100%;
        @media (max-width: 767px) {
            display: none;
        }

        #testimonial_next {
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
            width: 40%;
            cursor: none;
            transition: 0.7s all ${Transition};
            opacity: 0;
            //   cursor: pointer;
            //   height: 40px;
            //   width: 40px;
            //   background: #363231;
            //   border-radius: 50%;
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            //   position: relative;
            //   overflow: hidden;
                //   transition: 0.7s all ${Transition};
            //    border: 1px solid #363231; 
            //   &:after{
            //       position: absolute;
            //       inset: 0;
                //       background: ${hover};
            //       z-index: 1;
            //       content: '';
            //       width: 100%;
            //       border-radius: 50%;
            //       height: 100%;
                //       transition: 0.7s all ${Transition};
            //       transform: scale(0) ;
            //   }
            //   svg{
            //       position: relative;
            //       z-index: 3;
            //   }

            &:hover {
                height: 100%;
                cursor: url('/images/static/slider-right.svg') 32 32,
                auto;
                opacity: 1;
            }
        }
        #testimonial_prev {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            width: 40%;
            cursor: none;
            transition: 0.7s all ${Transition};
            opacity: 0;
            //   cursor: pointer;
            //   height: 40px;
            //   width: 40px;
            //   background: #363231;
            //   border-radius: 50%;
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            //   position: relative;
            //   overflow: hidden;
                //   transition: 0.7s all ${Transition};
            //    border: 1px solid #363231; 
            //   &:after{
            //       position: absolute;
            //       inset: 0;
                //       background: ${hover};
            //       z-index: 1;
            //       content: '';
            //       width: 100%;
            //       border-radius: 50%;
            //       height: 100%;
                //       transition: 0.7s all ${Transition};
            //       transform: scale(0) ;
            //   }
            //   svg{
            //       position: relative;
            //       z-index: 3;
            //   }

            &:hover {
                height: 100%;
                cursor: url('/images/static/slider-left.svg') 32 32,
                auto;
                opacity: 1;
            }
        }

    }

    .first_word {
        position: absolute;
        color: rgba(248, 246, 245, 0.1);
        font-size: 120px;
        font-weight: 600;
        line-height: 88px;
        left: 0;
        letter-spacing: -0.2px;
        top: 100px;
    }

    .second_word {
        position: absolute;
        color: rgba(248, 246, 245, 0.1);
        font-size: 120px;
        font-weight: 600;
        line-height: 88px;
        right: 0;
        letter-spacing: -0.2px;
        bottom: 100px;
    }

    .swiper-pagination {
        display: inline-flex;
        position: absolute;
        right: 0;
        top: 0;
        bottom: unset;
        justify-content: flex-end;
        height: 60px;
        align-items: center;
        left: unset;
        max-width: 50%;

        .swiper-pagination-bullet {
            outline: none;
            width: 100%;
            padding: 0;
            background: rgba(255, 255, 255, 0.5);
            margin: 0;
            height: 2px;
            transition: 0.5s all ${Transition};
            border-radius: 2px;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                height: 3px;
                background: rgba(255, 255, 255, 1);

            }

            &:hover {
                height: 3px;
                background: rgba(255, 255, 255, 1) !important;
            }
        }
    }

    .left {
        p {
            color: white;
            margin-bottom: 15px;
        }
    }

    .single-testimonial {
        p {
            color: white;
            transform: translateY(50px);
            opacity: 0;
            transition: all 0.5s ease;
            transition-delay: 0s;
        }

        display: flex;
        flex-direction: column;
        gap: 40px;

        .header {
            display: flex;
            gap: 20px;
            align-items: center;
            position: relative;
            overflow: hidden;

            .avatar {
                position: relative;
                height: 60px;
                width: 60px;
                border-radius: 50%;
                overflow: hidden;
                transform: translateY(60px);
                transition: 0.5s ease;
                transition-delay: 0s;
            }
        }
    }

    .content {
        transform: translateY(50px);
        opacity: 0;
        transition: all 0.5s ease;
        transition-delay: 0s;
        h6 {
            color: ${white};
            font-weight: 400;
        }
    }

    .swiper-slide:not(.swiper-slide-active) {
        opacity: 0 !important;
        transition: all 0.1s ease;
    }
    .swiper-slide-active {

        .content{
            opacity: 1;
            transform: none;
        }
        .testimonials__slides__single {
           
            &__desc {
                p {
                    opacity: 1;
                    transform: none;
                }
            }
            &__auth {
                img {
                    transform: none;
                }
                .groups {
                    transform: none;
                }
            }
        }

        .single-testimonial {
            p {
                opacity: 1;
                transform: none;
            }

            .header {
       

                .avatar {
                    opacity: 1;
                    transform: none;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .col-md-3 {
            margin-bottom: 60px;
        }

        .col-md-3, .col-md-9 {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    @media (max-width: 767px) {
        padding: 120px 0;
        .col-md-3 {
            margin-bottom: 30px;
        }

        .single-testimonial {
            padding-top: 80px;
        }

        .swiper-pagination {
            justify-content: flex-start;
        }

        .first_word {
            font-size: 80px;
            font-weight: 600;
            line-height: 80px;
        }

        .second_word {
            font-size: 80px;
            font-weight: 600;
            line-height: 80px;
        }
    }
`;

export default React.memo(Testimonial);
